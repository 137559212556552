import React from 'react';
import { Page, Row, Alert } from '../../shared';
import { RouteComponentProps } from 'react-router';

import * as BankApi from '../../../api/bank-statement';
import ExpensesForm, { ExpensesFormValues } from './expenses-form';
import { toCapitalCase } from '../../../utils/string-helper';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';

export interface SubmitExpensesProps extends BasePageProps, RouteComponentProps { }

//the outermost container for the expenses forms
const Expenses: React.FC<SubmitExpensesProps> = ({ appContext }) => {

  const { loading, navigate, executeCommandAndUpdateState, uiState: { analysisId, hasCentrelinkIncome } } = appContext;
  const handleSubmit = (values: ExpensesFormValues) => {

    let request: any = {
      analysisId: analysisId
    };

    Object.entries(values).forEach(([name, expense]) => {
      if (expense.amount === '' || expense.amount === undefined) return;
      var { amount, frequency, explanation = '', freetext = '', additionalExplanation } = expense;
      if (explanation === 'Other' || toCapitalCase(name) === 'Other') explanation = freetext;

      if(toCapitalCase(name) === 'Groceries') {
        if (!['Paid for by partner', 'Shared household expense', 'Living with parents / family'].includes(explanation)) {
          additionalExplanation = null;
        }
      }

      request = { ...request, [name]: { amount, frequency, explanation, additionalExplanation } };
    });

    executeCommandAndUpdateState(() => BankApi.declareExpenses(request), true)
      .then(() => navigate('LoanDeclaration'));
  };

  return (
    <Page title="Essential living expenses" lead="How much do you spend for each of the below." additionalInfo="This information helps us to 
      assess your application and is part of our responsible lender requirements.">
      <Row size="x-large">
        {hasCentrelinkIncome && <Alert id='expense-centrelink-warning' message='If you have expenses paid via Centrelink deduction, please do not include these below.' variant='info' className={'text-center'} />}
      </Row>
      <ExpensesForm onSubmit={handleSubmit} submitting={loading} initialValues={appContext.uiState.expenses as any} />
    </Page>
  );
}

export default canBeOnPage(Expenses, 'ExpenseDeclaration');