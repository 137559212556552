import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { FormikValidator, Row, PillButtonGroup, Submit, FormikSideEffects, SaveProgressButton } from '../../shared';
import ExpenseFormFields, { getValidators, ExpenseFormValues, getReasonsForType } from './expense-form-fields';
import { ExpenseType } from '../../../types/bank-statement';
import { toCapitalCase } from '../../../utils/string-helper';

interface ExpensesFormState {
  hideInsurance: boolean
  hideChildcare: boolean
  hideOther: boolean
}

export interface ExpensesFormValues {
  accommodation: ExpenseFormValues
  utilities: ExpenseFormValues
  groceries: ExpenseFormValues
  communication: ExpenseFormValues
  transport: ExpenseFormValues
  bnpl: ExpenseFormValues
  insurance?: ExpenseFormValues
  childcare?: ExpenseFormValues
  other?: ExpenseFormValues
}

interface ExpensesFormProps {
  initialValues?: ExpensesFormValues
  onSubmit: (values: ExpensesFormValues) => void
  submitting?: boolean
}

const ExpensesForm: React.FC<ExpensesFormProps> = (props) => {
  const { initialValues, onSubmit, submitting } = props;

  const doesAllowRemoval = (type: ExpenseType) => {
    const allowed: ExpenseType[] = ['Insurance', 'Other', 'Childcare'];
    return allowed.some(e => e === type);
  }

  //Setup validator and initial values
  const validator = new FormikValidator<ExpensesFormValues, ExpensesFormProps>({
    fields: [
      ...getValidators('Accommodation', doesAllowRemoval('Accommodation')),
      ...getValidators('Utilities', doesAllowRemoval('Utilities')),
      ...getValidators('Groceries', doesAllowRemoval('Groceries')),
      ...getValidators('Communication', doesAllowRemoval('Communication')),
      ...getValidators('Transport', doesAllowRemoval('Transport')),
      ...getValidators('Bnpl', doesAllowRemoval('Bnpl')),
      ...getValidators('Insurance', doesAllowRemoval('Insurance')),
      ...getValidators('Childcare', doesAllowRemoval('Childcare')),
      ...getValidators('Other', doesAllowRemoval('Other')),
    ]
  });

  const compiledInitialValues = validator.getInitial(initialValues);

  Object.keys(compiledInitialValues).forEach((key) => {
    //Handle None being sent by the server.  None is an invalid starting option
    const expenseObject = (compiledInitialValues as any)[key] as ExpenseFormValues;
    if (expenseObject.amount === undefined || expenseObject.amount === null || expenseObject.amount === '') {
      expenseObject.frequency = 'Weekly';
    }
    //Handle Users explanation being an 'Other' choice on return to page
    if (expenseObject.explanation) {
      const reasons = getReasonsForType(toCapitalCase(key) as ExpenseType);
      if (!reasons || !reasons.some(e => e === expenseObject.explanation)) {
        expenseObject.freetext = expenseObject.explanation;
        expenseObject.explanation = 'Other';
      }
    }
  });

  //check the current stored values to determine visibility of optionals
  const resolveInitialHide = (value?: ExpenseFormValues) => !(value && (value.amount !== '' && value.amount !== undefined));
  const [state, setState] = useState<ExpensesFormState>({
    hideInsurance: resolveInitialHide(compiledInitialValues.insurance),
    hideChildcare: resolveInitialHide(compiledInitialValues.childcare),
    hideOther: resolveInitialHide(compiledInitialValues.other)
  });

  const clearValues = (values?: ExpenseFormValues) => {
    if (!values) return;
    values.amount = undefined;
    (values.frequency as any) = undefined;
    values.explanation = undefined;
    values.freetext = undefined;
    values.additionalExplanation = undefined;
  };

  const handleToggleFormVisibility = (formikProps: FormikProps<ExpensesFormValues>, type: ExpenseType) => {
    const newState = { ...state };
    const newValues = { ...formikProps.values };
    switch (type) {
      case 'Insurance':
        newState.hideInsurance = !newState.hideInsurance;
        if (newState.hideInsurance) clearValues(newValues.insurance)
        break;
      case 'Childcare':
        newState.hideChildcare = !newState.hideChildcare;
        if (newState.hideChildcare) clearValues(newValues.childcare)
        break;
      case 'Other':
        newState.hideOther = !newState.hideOther;
        if (newState.hideOther) clearValues(newValues.other)
        break;
    }
    formikProps.setValues(newValues);
    setState(newState);
  }

  const optionalTypes: ExpenseType[] = ['Insurance', 'Childcare', 'Other'];
  const buttons = [
    { id: optionalTypes[0], text: 'Insurance', toggleOn: !state.hideInsurance },
    { id: optionalTypes[1], text: 'Childcare', toggleOn: !state.hideChildcare },
    { id: optionalTypes[2], text: 'Other', toggleOn: !state.hideOther }
  ];

  //adds all of the expense panels
  const formId = 'expense-form';
  return (
    <Formik<ExpensesFormValues>
      initialValues={compiledInitialValues as any}
      onSubmit={onSubmit}
      validate={(v) => validator.validate(v, props)}
      render={(formikProps) => {
        return (
          <form id={formId} onSubmit={formikProps.handleSubmit}>
            <Row size="x-large">
              <ExpenseFormFields
                formikProps={formikProps}
                type='Accommodation'
                subHeader='eg. Rent, Mortgage'
                headerImageSrc='/images/icons/loans/accommodation.svg'
                allowRemoval={doesAllowRemoval('Accommodation')}
              />
              <ExpenseFormFields
                formikProps={formikProps}
                type='Utilities'
                subHeader='eg. Gas, Electricity'
                headerImageSrc='/images/icons/loans/utilities.svg'
                allowRemoval={doesAllowRemoval('Utilities')}
              />
              <ExpenseFormFields
                formikProps={formikProps}
                type='Groceries'
                subHeader='eg. Food'
                headerImageSrc='/images/icons/loans/groceries.svg'
                allowRemoval={doesAllowRemoval('Groceries')}
              />
              <ExpenseFormFields
                formikProps={formikProps}
                type='Communication'
                subHeader='eg. Mobile, Internet'
                headerImageSrc='/images/icons/loans/communication.svg'
                allowRemoval={doesAllowRemoval('Communication')}
              />
              <ExpenseFormFields
                formikProps={formikProps}
                type='Transport'
                subHeader='eg. Petrol, Bus / Train'
                headerImageSrc='/images/icons/loans/transport.svg'
                allowRemoval={doesAllowRemoval('Transport')}
              />
              <ExpenseFormFields
                formikProps={formikProps}
                type='Bnpl'
                header='Buy Now Pay Later'
                subHeader='eg. AfterPay, ZipPay'
                headerImageSrc='/images/icons/loans/expenses.svg'
                allowRemoval={doesAllowRemoval('Bnpl')}
              />
            </Row>
            <Row size='small'>
              <h2>Add other essentials</h2>
              <PillButtonGroup buttons={buttons} onChange={(id: ExpenseType) => handleToggleFormVisibility(formikProps, id)} />
            </Row>
            <Row size='x-large'>
              {!state.hideInsurance && (
                <ExpenseFormFields
                  formikProps={formikProps}
                  type='Insurance'
                  subHeader='eg. Car, Health'
                  headerImageSrc='/images/icons/loans/insurance.svg'
                  allowRemoval={doesAllowRemoval('Insurance')}
                  onRemove={(type) => handleToggleFormVisibility(formikProps, type)}
                />
              )}
              {!state.hideChildcare && (
                <ExpenseFormFields
                  formikProps={formikProps}
                  type='Childcare'
                  subHeader='eg. School costs, Daycare'
                  headerImageSrc='/images/icons/loans/childcare.svg'
                  allowRemoval={doesAllowRemoval('Childcare')}
                  onRemove={(type) => handleToggleFormVisibility(formikProps, type)}
                />
              )}
              {!state.hideOther && (
                <ExpenseFormFields
                  formikProps={formikProps}
                  type='Other'
                  subHeader='Expenses not already listed'
                  headerImageSrc='/images/icons/loans/other.svg'
                  allowRemoval={doesAllowRemoval('Other')}
                  onRemove={(type) => handleToggleFormVisibility(formikProps, type)}
                />
              )}
            </Row>
            <Row size='small'>
              <p className='text-center'>The above details are true and up to date.</p>
              <Submit id='submit' text='Confirm' onSubmit={formikProps.handleSubmit} disabled={submitting} />
              <SaveProgressButton />
            </Row>
            <FormikSideEffects formId={formId} />
          </form>
        )
      }}
    />
  );
}

export default ExpensesForm;